import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Seo } from "../../components/SEO/Seo.jsx";
import CTABanner from "../../components/CTABanner/CTABanner";

import MainMenu from "../../components/MainMenu/MainMenu";
import Footer from "../../components/Footer/Footer.jsx";

import RectangleImg from "../../images/rectangle.svg";
import SkewRectangleHeroImg from "../../images/skewRectangleHero.png";

import { Link } from "gatsby";
import ArrowButton from "../../components/ArrowButton";
import TriangleImg from "../../images/triangle-img.svg";

import AOS from "aos";
import "aos/dist/aos.css";

const OffersList = ({ data }) => {
  const offersList = data.allMdx.nodes.filter(
    node => node.fields.source === "offers"
  );
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);
  return (
    <>
      <Seo
        title="Offers | GammaSoft: Custom Software Development Company"
        description="Check out our open positions"
      />
      <MainMenu />
      <div
        className="offers-hero"
        style={{ backgroundImage: `url(${SkewRectangleHeroImg})` }}
      >
        <div className="offers-hero-content container">
          <div
            className="offers-hero-content-left"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <h1 className="heading-primary">
              Find your next career opportunity with us
            </h1>
            <p className="paragraph-primary">
              If you're looking for a new opportunity to grow and make an
              impact, we invite you to explore our current job openings. Our
              team is dedicated to delivering high-quality results on
              interesting and challenging projects, and we believe that each
              team member plays a vital role in our success.
            </p>
          </div>
          <div className="offers-hero-content-right" data-aos="fade-left">
            <img src={RectangleImg} alt="" />
          </div>
        </div>
      </div>
      <section
        className="open-positions-section"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <img src={TriangleImg} alt="" />
        <div className="open-positions-section-content container">
          <h2 className="heading-secondary">Open positions</h2>
          <p className="paragraph-primary">
            Look at the positions we are currently hiring for.
          </p>
        </div>
        <div className="open-positions-section-list container">
          {offersList.map(offerListItem => {
            return (
              <div className="open-positions-section-list-item">
                <div className="open-positions-section-list-item-left">
                  <h3 className="heading-third">
                    {offerListItem.frontmatter.position}
                  </h3>
                  <span className="open-position-info">
                    {offerListItem.frontmatter.infoAboutPosition}
                  </span>
                </div>
                <div className="open-positions-section-list-item-right">
                  <Link
                    to={"/offers/" + offerListItem.frontmatter.slug}
                    key={offerListItem.id}
                    className="btn btn-secondary"
                  >
                    <ArrowButton text="More details" />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <CTABanner />
      <Footer />
    </>
  );
};

export default OffersList;

export const query = graphql`
  query OffersPage {
    allMdx(filter: { fields: { source: { eq: "offers" } } }) {
      nodes {
        fields {
          source
        }
        frontmatter {
          slug
          position
          infoAboutPosition
        }
        id
      }
    }
  }
`;
